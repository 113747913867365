body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Averta', 'AvertaBold',
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    135deg,
    #7d6e6e 16.67%,
    #ffffff 16.67%,
    #ffffff 50%,
    #7d6e6e 50%,
    #7d6e6e 66.67%,
    #ffffff 66.67%,
    #ffffff 100%
  );
  background-size: 4.24px 4.24px;
}

.lobster {
  font-family: 'Lobster', sans-serif;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Averta';
  src: local('Averta'),
    url('../src/fonts/Averta-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'AvertaBold';
  src: local('AvertaBold'),
    url('../src/fonts/Averta-ExtraBoldItalic.otf') format('truetype');
}
